.link {
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  transition: color 0.3s cubic-bezier(0.11, 0.7, 0, 1);
  letter-spacing: 0.15rem;
  line-height: 1.8;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  color: #000026;
  padding: 16px;
  flex-shrink: 0;
}

.link:hover {
  color: #f0a202;
}

.link:after {
  position: absolute;
  bottom: 10;
  left: auto;
  display: block;
  width: calc(100% - 32px);
  height: 3px;
  background-color: #f0a202;
  content: "";
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.11, 0.7, 0, 1);
}

.link:hover:after {
  transform: scale(1);
}

.logo {
  height: 100%;
  padding: 16;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
