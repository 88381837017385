html {
  height: 100%;
  width: 100%;
  margin: 0;
}
body {
  width: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
* {
  outline-color: transparent !important;
}
*:focus {
  outline: none !important;
}
