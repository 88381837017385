form {
  flex: 1;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
}

h1 {
  margin-top: 0;
  margin-bottom: 3.236rem;
  text-align: center;
  font-size: 1.618rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.form-group {
  padding: 1rem 0.5rem;
  margin-right: 1rem;
  flex: 1;
  border: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  touch-action: manipulation;
  font-family: Roboto;
  font-weight: lighter;
  font-size: 18px;
  color: #928f8f;
}

input {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #4a4d50;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
  resize: none;
}
input:focus {
  outline: 0;
  border-bottom-color: #f0a202;
}
